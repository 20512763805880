<template>
  <div class="wrapper">
    <transition name="fade">
      <forgot-password-modal ref="modalForgotPassword" v-show="forgotPassword" @closeModal="forgotPassword = false"
        color=" secondary" req="site/parceiro" />
    </transition>
    <div class="form_section container">
      <div class="form_content">
        <div class="logo" @click="$router.push('/')"></div>
        <div v-if="this.$route.query.bearerToken && !this.$route.query.user" class="form_title">Confirmando
          autenticação remota de administrador, aguarde...</div>
        <div v-if="this.$route.query.bearerToken && this.$route.query.user" class="form_title">Confirmando
          autenticação e redirecionando para o painel, aguarde...</div>
        <div v-if="!this.$route.query.bearerToken" class="form_title">Olá parceiro!</div>
        <div v-if="!this.$route.query.bearerToken" class="form_title">Faça login e acompanhe suas propostas</div>
        <form v-if="!this.$route.query.bearerToken" @submit.prevent="login" @keyup.enter="login"
          class="w-full flex flex-col mt-4 lg:mt-10">
          <v-input v-if="$route.query.administrador" name="adminEmail" v-lowercase v-trim v-model="form.adminEmail"
            :error="errors.first('adminEmail')" label="E-mail de Administrador" v-validate="'required|email'"></v-input>
          <v-input name="email" v-lowercase v-trim v-model="form.email" :error="errors.first('email')" label="E-mail"
            v-validate="'required|email'"></v-input>
          <v-input name="password" type="password" v-model="form.password" :error="errors.first('password')" label="Senha"
            v-validate="'required'"></v-input>
          <p class="pass_recovery" @click="showModalForgotPassord">Esqueci minha senha</p>

          <v-button color="primary" class="self-end" :loading="loading">Entrar</v-button>
        </form>
      </div>
      <small style="color: #333; font-size: 0.6em"> {{ new Date() }}</small>

      <div class="footer">
        Copyright © TuTu Digital. Todos os Direitos Reservados. Tutu Digital
        Tecnologia Ltda – CNPJ 28.304.222/0001-17, Avenida Paulista, 1374 -
        Andar 11, São Paulo, SP. CEP 01310-100.
      </div>
    </div>
  </div>
</template>

<script>
import VInput from "@/components/Inputs/Input";
import VButton from "@/components/Buttons/Button";

import Login from "@/services/Landing/Login";
import LoginBearer from '@/services/Commons/LoginBearer'
import ForgotPasswordModal from '@/components/Modal/ForgotPasswordModal'

export default {
  components: { VInput, VButton, ForgotPasswordModal },
  data() {
    return {
      forgotPassword: false,
      loading: false,

      form: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    showModalForgotPassord() {
      this.forgotPassword = true;
      this.$refs.modalForgotPassword.setEmail(this.form.email)
    },
    async login() {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true;
          var response;
          try {
            const grecaptcha = await this.$recaptcha("login");
            response = (await Login.login({ ...this.form, grecaptcha }));

            console.info(`login: verificando integridade do retorno - ${this.form.email}`)
            if (response == null || ((response.data == null || response.data.data == null) && !response.data.erros)) {
              this.$store.dispatch('notification', { type: 'error', message: 'Houve um erro na requisição de login' })
              console.error(response);
            }

            if (!response.data.data.roles.includes("parceiro_tomador"))
              throw new Error("roleError");

            this.$store.dispatch("setLogout");
            this.$store.dispatch("setPartner", { email: this.form.email, name: 'Nome' });

            this.$store.dispatch("setLogin", response.data.data);

            this.$router.push("/parceiro/painel");
          } catch (error) {
            this.$store.dispatch('notificationErrors', error.response ? error.response.data.erros : response.data.erros);
          } finally {
            this.loading = false;
          }
        } else {
          this.$store.dispatch("notification", {
            type: "error",
            message: "Há algum campo inválido. Revise o formulário."
          });
        }
      }
    }
  },
  async mounted() {
    if (this.$route.query.bearerToken) {
      console.log(this.$route.query.bearerToken)
      try {
        const response = (
          await LoginBearer.loginBearer({
            bearerToken: this.$route.query.bearerToken
          })
        ).data.data;
        this.$store.dispatch("setLogout");
        this.$store.dispatch("setLogin", response);
        if (!response.roles.includes("parceiro_tomador"))
          throw new Error("roleError");
        this.$router.push("/parceiro/painel");
      } catch (error) {
        this.$router.push('/parceiro/login')
      }
    }
  }
};
</script>

<style lang="sass" scoped>

.wrapper
  @apply w-screen h-screen flex flex-col items-center justify-center
  @screen lg
    @apply flex-row

.form_section
  @apply flex flex-col items-center justify-center w-full

.form_content
  @apply flex flex-col items-center
  @screen lg
    @apply my-auto

.logo
  @apply bg-no-repeat h-16 w-40 my-5 cursor-pointer
  background-image: url("../../assets/images/logo.svg")
  @screen lg
    @apply mb-10

.form_title
  @apply text-center mb-4 text-gray-700
  @screen lg
    @apply text-2xl

.pass_recovery
  @apply mb-4 self-end text-sm text-gray-700 cursor-pointer
  &:hover, &:focus
    @apply text-primary

.footer
  @apply text-center text-xss text-gray-700 leading-relaxed mt-8
  @screen lg
    @apply mb-4 text-xs
</style>
